.login {
  &__container {
    width: 100%;
    height: 100vh;
    background-color: #e3f2fd;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  &__card {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 430px;
    padding-bottom: 70px;
  }
}
