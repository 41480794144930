.loading{
    &__full{
        position: fixed;
        top: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.4);
        z-index: 999999;
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            position: absolute;
            opacity: 1 !important;
            transform: translate(-50%, -50%);
        }
    }
    &__block{
       @extend .loading__full;
       background: none !important; 
    }
}