$--breadcrumb-theme-1: #203462;
$--breadcrumb-theme-2: #f6f6f6;
$--breadcrumb-theme-3: #ffffff;
$--breadcrumb-theme-4: #c3cada;

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    margin-bottom: 1rem;
    list-style: none;
	background-color: #ffffff !important;
	counter-reset: flag;
    padding: unset !important;
    flex-wrap: unset;
	text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border-radius: .25rem;

	.breadcrumb__step {
		white-space: nowrap;
		text-decoration: none;
		outline: none;
		display: block;
		float: left;
		font-size: 14px;
		font-weight: 600;
		height: 46px;
		line-height: 46px;
		padding: 0px 10px 0px 35px;
		position: relative;
		background: $--breadcrumb-theme-2;
		color: $--breadcrumb-theme-1;
		transition: background 0.5s;
		width: 17%;
		border: 0px;

		&:first-child {
			padding-left: 16px;
		}

		&:first-child::before {
			left: unset !important;
		}
		

		&::before  {
			content: "";
			position: absolute;
			top: -17px !important;
			right: -13px !important;
			width: 40px !important;
			height: 40px !important;
			border-width: 20px !important;
			border-color: #ffffff #ffffff transparent transparent !important;
			border-style: solid !important;
			transform: rotate(45deg) !important;
			z-index: 1 !important;
			border-radius: 0px;
		}

		&::after {
			overflow: hidden;
			content: "";
			position: absolute;
			top: -17px !important;
			right: -12px !important;
			width: 40px !important;
			height: 40px !important;
			border-width: 20px !important;
			border-style: solid !important;
			box-shadow: unset !important;
			border-radius: 3px !important;
			background: #f6f6f6;
			background-color: transparent !important;
			transform: scale(1) rotate(45deg) !important;
			transition: all 0.5s !important;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			z-index: 2 !important;
		}

		p {
			white-space: pre-line;
			line-height: 1.3;		
		}
	}

	.breadcrumb__step--active {
		background: $--breadcrumb-theme-1;
		color: $--breadcrumb-theme-2 !important;

		&::after {
			background: $--breadcrumb-theme-1;
			border-color: $--breadcrumb-theme-1 $--breadcrumb-theme-1 transparent transparent !important;
		}
	}

	.breadcrumb__step--disabled {
		background: $--breadcrumb-theme-4;
		color: #1C1D1F !important;

		&::after {
			background: $--breadcrumb-theme-4;
			border-color: $--breadcrumb-theme-4 $--breadcrumb-theme-4 rgba(0,0,0,0) rgba(0,0,0,0) !important;
		}
	}
}