*{ 
  margin: 0;
  padding: 0;
}

a,
a:visited,
a:hover,
a:active {
  color: rgb(0,0,238);
}