.purchase {
  &__container {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #e3f2fd;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  &__inputEmail {
    min-height: 500px;
  }
  &__padding_10 {
    padding: 10% 0;
  }
  &__registerNotify {
    min-height: 90vh;
  }
}

.fs-09-rem {
  font-size: 0.9rem;
}

.purchase-button-gray {
  background-color: gray;

  &:hover {
    background-color: #636569;
    text-decoration: none;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}
.breadcrumb__step {
  flex-grow: 1;
  flex-basis: 0;
}

.text-error {
  color: #95010c;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Noto Sans JP, Lato, 游ゴシック Medium, 游ゴシック体, Yu Gothic Medium, YuGothic, ヒラギノ角ゴ ProN,
    Hiragino Kaku Gothic ProN, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
  line-height: 1.66;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.required-asterisk {
  color: red;
  margin-left: 4px;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('../../font/NotoSansJP-Regular.ttf');
}

.font-NotoSansJP, .font-pdf th, .font-pdf td, .font-pdf p, #mui-1, #mui-3 {
  font-family: 'NotoSansJP';
}
